@font-face 
  font-family: helvetica-neue
  font-weight: 100
  src: url('./../fonts/HelveticaNeue\ Light.ttf')

@font-face 
  font-family: helvetica-neue
  font-weight: 400
  src: url('./../fonts/HelveticaNeue\ Medium.ttf')


@font-face 
  font-family: helvetica-neue
  font-weight: 700
  src: url('./../fonts/HelveticaNeue\ Bold.ttf')

html
  scroll-behavior: smooth
  
body
  margin: 0
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

h1, h2, h3, h4, h5, h6, p
  margin: 0
  margin-block: 0