.container
  background-image: linear-gradient(#1e3c72, #005496, #1e3c72)
  height: 100vh
  box-sizing: border-box
  padding: 0 10vw
  color: #fbfbfc

.title
  cursor: default
  width: 100%
  height: 140px
  line-height: 140px
  text-align: center
  font-size: 50px
  font-style: italic
  font-family: 'Oswald'
  font-weight: bolder
  letter-spacing: 5px
  // text-transform: uppercase
  text-shadow: 3.5px 3.5px #66aee7
  border-bottom: 1px solid #fbfbfb

.selectContainer
  padding-top: 300px
  display: flex
  align-items: center
  justify-content: center
  & > *
    margin: 0 20px
  
.selectQuestion
  text-align: center
  cursor: pointer
  font-size: 32px
  width: 120px
  padding: 16px 20px
  border: 1px solid #fbfbfb
  border-radius: 4px
  transition: all .2s ease-in
  &:hover
    background-color: orange

.question
  font-size: 32px
  padding-top: 100px

.answers
  display: grid
  grid-template-columns: 1fr 1fr
  grid-template-rows: 1fr 1fr
  justify-content: space-between
  margin-top: 80px
  .answer
    cursor: pointer
    border: 1px solid #fbfbfb
    width: 80%
    font-size: 24px
    margin: 40px 0
    padding: 20px
    box-sizing: border-box
    border-radius: 4px
    transition: all .2s ease-in
    &:nth-child(even)
      margin-left: 20%
    &:hover
      background-color: orange
      
.endContainer
  display: flex
  justify-content: center
  align-items: center
  height: 100%
  width: 100%
  .title
    border: none